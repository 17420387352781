import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { CheckBox } from '../../../../../../components/form/CheckBox';
import { useState } from 'react';
import { Field } from 'react-final-form';
// import { RichTreeView } from '@mui/x-tree-view';

const ousQuery = gql`
  query {
    ous
  }
`;

interface OuProps {
  id: string;
  externalId: string; 
  name: {
    en: string;
    nb: string;
    nn: string;
  }
  children: [] | null
}

const OrganizationalUnit = ({ unit, level }: any) => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const [checkBoxChecked, setCheckBoxChecked] = useState(false)

  return (
    <div>
      <div style={{ paddingLeft: `${level * 20}px` }}>
            {/* <Field
                type="checkbox"
                name="ous"
                label={unit.name[lang]}
              >
                {({ input, label }) => (
                  <CheckBox
                    {...input}
                    disabled={checkBoxChecked}
                    label={label}
                  />
                )}
              </Field> */}
              {unit.name[lang]}
      </div>
      {unit.children && unit.children.map((child: any) => (
        <OrganizationalUnit key={unit.id} unit={child} level={level + 1} />
      ))}
    </div>
  );
  
};

const OrgregTree = () => {
  const { t } = useTranslation(['admin']);
  const { data, loading, error }  = useQuery(ousQuery);

  if (loading) {
    return <div>{t('ous.loadingOus')}</div>;
  }
  if (error) {
    return <div>{t('ous.loadingError')}</div>;
  }

  const ou = data.ous
  console.log(ou)
  return (
    <div>
        <OrganizationalUnit key={ou.id} unit={ou} level={0} />
        {/* <RichTreeView items={ou}/> */}
    </div>
  )
}

export default OrgregTree
